import classNames from 'classnames';
import Preact from 'preact';
import { SiteMapItem } from '../../content';
import testId from '../../helpers/testId';
import AnimatedChevron from '../AnimatedChevron';

interface Props {
  menuItem: SiteMapItem;
  fullWidth: boolean;
  showAllItems?: boolean;
  isExpanded?: boolean;
}

interface State {
  isExpanded: boolean;
}

class FooterTextSection extends Preact.Component<Props, State> {
  state = {
    isExpanded: false,
  };

  handleOpen = () => {
    this.setState({ isExpanded: true });
  };

  handleCollapse = () => {
    this.setState({ isExpanded: false });
  };

  onButtonClick = (id: string) => {
    if (this.state.isExpanded) {
      this.handleCollapse();
    } else {
      this.handleOpen();
      const element = document.querySelector(`#${id} ul li:nth-child(5) a`) as HTMLAnchorElement;
      if (element) {
        element.focus();
      }
    }
  };

  render() {
    const { menuItem, fullWidth, showAllItems } = this.props;
    const { isExpanded } = this.state;
    const displayLinksEndIndex =
      isExpanded || showAllItems ? menuItem.links.length : 5;
    const id =
      'foot-text-section-' + menuItem.title.replace(/ +/g, '-').toLowerCase();
    return (
      <nav
        id={id}
        className={classNames(
          testId('footer-text__group'),
          fullWidth ? 'col-lg-12' : 'col-lg-6 col-xl-3',
          'bottom-border',
          fullWidth ? 'jg-space-mtmd@xs' : '',
          'jg-space-mtmd@xl',
          'jg-space-mbxs@xl',
          'jg-space-mbsm@sm',
        )}
      >
        <h2
          className={classNames(
            testId('footer-text__group__title'),
            'jg-space-mbmd@xl',
            'jg-space-mtsm',
            'jg-h5',
          )}
        >
          {menuItem.title}
        </h2>
        <ul
          className={classNames(
            fullWidth ? 'jg-list--inline' : 'jg-list--unstyled',
          )}
        >
          {menuItem.links
            .slice(0, displayLinksEndIndex)
            .map((item, itemIdx) => (
              <li key={itemIdx}>
                <a
                  className={classNames(
                    testId('footer-text__group__link'),
                    'jg-text--regular',
                    'jg-display-b',
                    'jg-space-pvxs',
                    'jg-color--empress',
                    'jg-color--nero@hover',
                    'jg-text-no-underline@hover',
                  )}
                  href={item.url}
                >
                  {item.label}
                </a>
              </li>
            ))}
          {menuItem.links.length > 5 && !this.props.showAllItems && (
            <li key={`show-more-less-${menuItem.title}`}>
              <button
                onClick={() => this.onButtonClick(id)}
                className={classNames(
                  'jg-text--regular',
                  'jg-display-b',
                  'jg-space-pvxs',
                  'jg-color--empress',
                  'jg-color--nero@hover',
                  'jg-text-no-underline@hover',
                  'jg-background--transparent',
                  'jg-cursor-pointer',
                  'jg-space-pln',
                  'jg-text--pull-left',
                  'jg-w-full',
                  'show-more',
                )}
              >
                {this.state.isExpanded ? 'Show less' : 'Show more'}
                <AnimatedChevron
                  isOpen={this.state.isExpanded}
                  className={classNames(
                    testId('menu__button-caret'),
                    'jg-chrome-dropdown-menu__button-caret',
                    'jg-display-ib',
                    'jg-align--vertical-middle',
                    'jg-icon--medium-small@lg',
                  )}
                />
              </button>
            </li>
          )}
        </ul>
      </nav>
    );
  }
}

export default FooterTextSection;
